import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {
  Grid,
  MenuItem,
  Checkbox,
  ListSubheader,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import {Modal} from 'shared/components';
import {useCampaign} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {
  FormService,
  IFormSectionPost,
  IPredefinedSection,
  ISituacaoFicha,
} from 'shared/services/api/FormService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  open: boolean;
  section?: IFormSectionPost;
  sectionId?: string;
  order?: number;
  onClose(): void;
  formSteps?: ISituacaoFicha[];
}

export const ModalSection: React.FC<IProps> = ({
  open,
  onClose,
  order,
  section,
  sectionId,
  formSteps,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [predefinedSection, setPredefinedSection] = useState<
    IPredefinedSection[]
  >([]);
  const [sectionField, setSectionField] = useState<IFormSectionPost>({
    idCampanha: '',
    idSecaoPredefinida: 0,
    nome: '',
    apresentarNoFormulario: true,
    maximoDeColunas: 1,
    ehFichaComplementar: false,
    idSituacaoLead: '',
  } as IFormSectionPost);

  useEffect(() => {
    if (section) {
      setSectionField(section);
    }
  }, [section]);

  const {getSections, sectionFiltered} = useCampaign();
  const {id = '0'} = useParams<'id'>();
  const handleGetPredefinedSection = useCallback(async () => {
    try {
      const response = await FormService.getPredefinedSection();
      setPredefinedSection(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCloseAndResetSection = useCallback(() => {
    setSectionField({
      idCampanha: '',
      idSecaoPredefinida: 0,
      nome: '',
      apresentarNoFormulario: true,
      maximoDeColunas: 1,
      ehFichaComplementar: false,
      idSituacaoLead: '',
    } as IFormSectionPost);
    onClose();
  }, [onClose]);

  const handleCreateOrUpdateSection = useCallback(async () => {
    setIsLoading(true);
    if (sectionField.nome.length === 0) {
      setSubmitted(true);
      setIsLoading(false);
      return;
    }
    try {
      if (section && sectionId && order) {
        await FormService.updateFormSection(sectionId, {
          ...sectionField,
          idSecaoPredefinida:
            sectionField.idSecaoPredefinida === 0
              ? null
              : sectionField.idSecaoPredefinida,
          ordem: order,
        });
      } else {
        await FormService.postFormSection({
          ...sectionField,
          idCampanha: id,
          idSecaoPredefinida:
            sectionField.idSecaoPredefinida === 0
              ? null
              : sectionField.idSecaoPredefinida,
          ehFichaComplementar: sectionField.ehFichaComplementar ?? false, //TODO: Fazer mudar certo o valor conforme for ou nao uma ficha complementar.
          idSituacaoLead: sectionField.idSituacaoLead
            ? sectionField.idSituacaoLead
            : null,
        });
      }
      feedback('Regitro criado com sucesso', 'success');
      await getSections(id);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }

    setSectionField({
      idCampanha: id,
      idSecaoPredefinida: 0,
      nome: '',
      apresentarNoFormulario: true,
      maximoDeColunas: 1,
      ehFichaComplementar: false,
      idSituacaoLead: '',
    } as IFormSectionPost);

    onClose();
  }, [sectionField, getSections, id, onClose, section, sectionId, order]);

  const handleUsedSectionName = useCallback(() => {
    if (section && sectionId && order) {
      return (
        sectionFiltered
          .filter((i) => i.idSecao !== sectionId)
          .filter(
            (i) =>
              i.nomeSecao?.toLowerCase().trim() ===
              sectionField.nome?.toLowerCase().trim(),
          ).length > 0
      );
    }

    return (
      sectionFiltered.filter(
        (i) =>
          i.nomeSecao?.toLowerCase().trim() ===
          sectionField.nome?.toLowerCase().trim(),
      ).length > 0
    );
  }, [order, section, sectionField.nome, sectionFiltered, sectionId]);

  const supplementaryFormFields = useMemo(
    () => predefinedSection.filter((item) => item.ehFichaComplementar),
    [predefinedSection],
  );
  const predefinedFields = useMemo(
    () => predefinedSection.filter((item) => !item.ehFichaComplementar),
    [predefinedSection],
  );

  useEffect(() => {
    handleGetPredefinedSection();
  }, [handleGetPredefinedSection]);

  useEffect(() => {
    setSectionField((state) => (state = {...state, idCampanha: id}));
  }, [id]);

  return (
    <Modal
      labelSaveButton={section ? 'Salvar' : 'Incluir'}
      labelCloseButton="Cancelar"
      onClick={handleCreateOrUpdateSection}
      onClose={handleCloseAndResetSection}
      opened={open}
      loading={isLoading}
      title={section ? 'Editar dados da seção' : 'Dados da seção'}
      maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sectionField.apresentarNoFormulario}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSectionField(
                    (state) =>
                      (state = {
                        ...state,
                        apresentarNoFormulario: event.target.checked,
                      }),
                  );
                }}
              />
            }
            label="Visível no formulário"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            error={(!sectionField.nome && submitted) || handleUsedSectionName()}
            helperText={
              !sectionField.nome && submitted
                ? 'Campo requerido'
                : handleUsedSectionName() &&
                  'O nome da seção deve ser único por ficha de inscrição.'
            }
            value={sectionField.nome}
            label="Título da seção *"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSectionField(
                (state) => (state = {...state, nome: event.target.value}),
              );
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            value={sectionField.idSituacaoLead || '99'}
            label="Etapa de envio de formulário"
            disabled={sectionField.idSecaoPredefinida === 1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSectionField(
                (state) =>
                  (state = {
                    ...state,
                    idSituacaoLead:
                      event.target.value === '99' ? '' : event.target.value,
                  }),
              );
            }}
            fullWidth
            select>
            <MenuItem value="99">
              <em>Formulário de inscrição</em>
            </MenuItem>
            {formSteps?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            value={
              sectionField.idSecaoPredefinida
                ? sectionField.idSecaoPredefinida
                : ''
            }
            label="Seção predefinida"
            disabled={sectionField.idSecaoPredefinida === 1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSectionField(
                (state) =>
                  (state = {
                    ...state,
                    idSecaoPredefinida: Number(event.target.value),
                  }),
              );
            }}
            fullWidth
            select>
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {supplementaryFormFields.length > 0 && (
              <ListSubheader>Ficha Complementar</ListSubheader>
            )}
            {supplementaryFormFields
              .filter((item) => item.ehFichaComplementar)
              .map((item) => (
                <MenuItem
                  key={item.id}
                  disabled={item.id === 1}
                  value={item.id}
                  onClick={() =>
                    setSectionField(
                      (state) =>
                        (state = {
                          ...state,
                          ehFichaComplementar: item.ehFichaComplementar,
                        }),
                    )
                  }>
                  {item.nome}
                </MenuItem>
              ))}

            {predefinedFields.length > 0 && (
              <ListSubheader>Pré-definidos</ListSubheader>
            )}
            {predefinedFields
              .filter((item) => !item.ehFichaComplementar)
              .map((item) => (
                <MenuItem
                  key={item.id}
                  disabled={item.id === 1}
                  value={item.id}
                  onClick={() =>
                    setSectionField(
                      (state) =>
                        (state = {
                          ...state,
                          ehFichaComplementar: item.ehFichaComplementar,
                        }),
                    )
                  }>
                  {item.nome}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            error={!sectionField.maximoDeColunas && submitted}
            helperText={
              !sectionField.maximoDeColunas && submitted && 'Campo requerido'
            }
            value={sectionField.maximoDeColunas}
            label="Nº de Colunas *"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSectionField(
                (state) =>
                  (state = {
                    ...state,
                    maximoDeColunas: Number(event.target.value),
                  }),
              );
            }}
            fullWidth
            select>
            {[1, 2, 3]?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            value={sectionField.descricao}
            label="Descrição da seção"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSectionField(
                (state) =>
                  (state = {
                    ...state,
                    descricao: event.target.value,
                  }),
              );
            }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
