import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Table,
  Paper,
  Dialog,
  Tooltip,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import {Apps, Close, GetApp, FileCopy} from '@material-ui/icons';

import {useNavigate} from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';

import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {useTitle, useUser} from 'shared/hooks';
import {Button, NoRegister, TableSkeleton} from 'shared/components';

import {
  IUrlResponse,
  IUrlComposition,
  ExternalUrlService,
} from 'shared/services/api/ExternalUrlService';
import {CampaignService, ICampaign} from 'shared/services/api/CampaignService';

export const ExternalUrl: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState<ICampaign[]>([]);
  const [dataResponse, setDataResponse] = useState<IUrlResponse[]>([]);
  const [dataRequest, setDataRequest] = useState<IUrlComposition>(
    {} as IUrlComposition,
  );

  const [openQRCode, setOpenQRCode] = useState(false);
  const [selectedQRCode, setSelectedQRCode] = useState('');

  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('URL')),
    [user?.permissoes],
  );

  const canGenerate = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Gerar')),
    [permissions],
  );

  const getCampaigns = useCallback(async () => {
    try {
      setLoading(true);
      const data = await CampaignService.getCampaigns({});

      if (data) {
        setCampaign(data);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetExternalUrl = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ExternalUrlService.getExternalUrl(dataRequest);
      if (response) {
        setDataResponse(response);
      }

      feedback('URL externa gerada com sucesso', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [dataRequest]);

  const createHead = useCallback(() => {
    switch (dataRequest.idComposicaoUri) {
      case 1:
        return (
          <TableRow>
            <TableCell width={80} align="center">
              Ações
            </TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        );
      case 2:
        return (
          <TableRow>
            <TableCell width={80} align="center">
              Ações
            </TableCell>
            <TableCell>Estabelecimento</TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        );
      default:
        return (
          <TableRow>
            <TableCell width={80} align="center">
              Ações
            </TableCell>
            <TableCell>Estabelecimento</TableCell>
            <TableCell>Curso</TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        );
    }
  }, [dataRequest.idComposicaoUri]);

  const copyToClipboard = (uri: string) => {
    navigator.clipboard.writeText(uri);
    feedback('URL copiada para sua área de transferência!', 'success');
  };

  const handleOpenQRCode = (uri: string) => {
    setOpenQRCode(true);
    setSelectedQRCode(uri);
  };

  const handleCloseQRCode = () => {
    setOpenQRCode(false);
  };

  const handleDownloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl');

    if (qrCodeURL) {
      const svgData = new XMLSerializer().serializeToString(qrCodeURL);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0);

        const pngData = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = pngData;
        link.download = 'qrcode.png';
        link.click();

        img.src = '';
        canvas.remove();
      };
      img.onerror = () => {
        console.error('Erro ao baixar QR code');
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  useEffect(() => setTitle('Geração URL para inscrição externa'), [setTitle]);

  useEffect(() => {
    if (!canGenerate) navigate('/');
  }, [canGenerate, navigate]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => setDataResponse([]), [dataRequest.idComposicaoUri]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            disabled={loading}
            size="small"
            variant="outlined"
            label="Campanha *"
            value={dataRequest.idCampanha}
            onChange={({target}) => {
              setDataRequest({
                ...dataRequest,
                urlBase: window.location.href.replace(
                  'crm.gvdasa.com.br/url-externa',
                  'crmform.gvdasa.com.br/formulario',
                ),
                idCampanha: target.value,
              });
            }}
            fullWidth
            select>
            {campaign?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nomeCompleto}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            disabled={loading}
            size="small"
            variant="outlined"
            label="Composição da URL *"
            value={dataRequest.idComposicaoUri}
            onChange={({target}) => {
              setDataRequest({
                ...dataRequest,
                idComposicaoUri: Number(target.value),
              });
            }}
            fullWidth
            select>
            <MenuItem value={1}>Única por campanha</MenuItem>
            <MenuItem value={2}>Por estabelecimento</MenuItem>
            <MenuItem value={3}>Por estabelecimento e Curso</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            disabled={
              loading ||
              !canGenerate ||
              !dataRequest.idCampanha ||
              !dataRequest.idComposicaoUri
            }
            fullWidth
            label="Gerar"
            onClick={handleGetExternalUrl}
          />
        </Grid>
      </Grid>

      <Box marginTop={5} />

      {loading ? (
        <TableSkeleton btnAct={1} rowsBody={5} rectWidth="80%" />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>{createHead()}</TableHead>

            <TableBody>
              {dataResponse.length > 0 &&
                dataResponse.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" gridGap={4}>
                        <Tooltip title={`Copiar URL`}>
                          <IconButton
                            color="primary"
                            onClick={() => copyToClipboard(item.uri)}>
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Gerar QR Code`}>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenQRCode(item.uri)}>
                            <Apps />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    {dataRequest.idComposicaoUri !== 1 && (
                      <TableCell>{item.estabelecimento}</TableCell>
                    )}
                    {dataRequest.idComposicaoUri === 3 && (
                      <TableCell>{item.curso}</TableCell>
                    )}
                    <Tooltip title={`Copiar URL`}>
                      <TableCell>
                        <TextField
                          onClick={() => copyToClipboard(item.uri)}
                          disabled
                          fullWidth
                          size="small"
                          value={item.uri}
                        />
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <NoRegister data={dataResponse.length} />
        </TableContainer>
      )}

      <Dialog open={openQRCode} onClose={handleCloseQRCode}>
        <Box display="flex" justifyContent="flex-end" pt={2} px={2}>
          <IconButton onClick={handleCloseQRCode}>
            <Close />
          </IconButton>
        </Box>
        <Box mx={1} mb={2}>
          <QRCodeSVG
            id="qrCodeEl"
            includeMargin
            value={selectedQRCode}
            size={280}
            bgColor="#ffffff"
            fgColor="#000000"
            level="L"
          />
          <Box display="flex" justifyContent="center">
            <Tooltip title="Baixar QR code">
              <Button
                color="primary"
                startIcon={<GetApp />}
                onClick={handleDownloadQRCode}>
                Download
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
